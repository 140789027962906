import { TransactionType } from "./fetchForAddress";

enum Colors {
  ROOT = "grey",
  COLOR1 = "rgb(71,73,220)",
  COLOR2 = "rgb(45,120,72)",
  COLOR3 = "rgb(155,55,128)",
  COLOR4 = "rgb(127,33,87)",
  COLOR5 = "rgb(22,55,25)",
  COLOR6 = "rgb(209,31,11)",
  COLOR7 = "rgb(18,92,185)",
  COLOR8 = "rgb(116,53,2)",
  PINK = "pink",
}

export type Link = {
  source: string;
  target: string;
  value: number;
  opacity: number;
  extras: Transaction[];
  color: Colors;
};
export type Transaction = {
  blockHash: string;
  blockNumber: string;
  confirmations: string;
  contractAddress: string;
  cumulativeGasUsed: string;
  from: string;
  gas: string;
  gasPrice: string;
  gasUsed: string;
  hash: string;
  input: string;
  nonce: string;
  timeStamp: string;
  to: string;
  tokenDecimal: string;
  tokenName: string;
  tokenSymbol: string;
  transactionIndex: string;
  value: string;
  type?: TransactionType;
  parent: string;
};

const compareColor = (
  color1: Colors,
  color2: Colors,
  color3: Colors,
  color4: Colors
) =>
  [color1, color2].sort().every((val, i) => [color3, color4].sort()[i] === val);

const getColor = (existingColor: Colors, incomingColor: Colors) => {
  console.log("get");
  const compoundColors = [Colors.COLOR5, Colors.COLOR6, Colors.COLOR7];

  if (compoundColors.includes(existingColor) || existingColor === Colors.ROOT) {
    return Colors.ROOT;
  }
  console.log(
    compareColor(existingColor, incomingColor, Colors.COLOR1, Colors.COLOR2)
  );
  if (
    compareColor(existingColor, incomingColor, Colors.COLOR1, Colors.COLOR2)
  ) {
    return Colors.COLOR5;
  }
  if (
    compareColor(existingColor, incomingColor, Colors.COLOR3, Colors.COLOR4)
  ) {
    return Colors.COLOR6;
  }
  if (
    compareColor(existingColor, incomingColor, Colors.COLOR1, Colors.COLOR4)
  ) {
    return Colors.COLOR7;
  }
  if (
    compareColor(existingColor, incomingColor, Colors.COLOR3, Colors.COLOR2)
  ) {
    return Colors.COLOR8;
  }

  return Colors.PINK;
};
const parseGraph = (
  allTransactions: Transaction[],
  x: number,
  y: number,
  lastFetchSize: number
) => {
  lastFetchSize = lastFetchSize / 2;
  console.time("Get all tx");
  const allAddresses: { id: string; parent: string }[] = [];

  const links: Link[] = [];

  allTransactions.forEach((transaction) => {
    const { to, from, value, type, parent } = transaction;
    if (!to || !from) {
      return;
    }
    if (from && !allAddresses.find((a) => a.id === from)) {
      allAddresses.push({ id: from, parent });
    }
    if (to && !allAddresses.find((a) => a.id === to)) {
      allAddresses.push({ id: to, parent });
    }
    const link: Link = {
      source: from,
      target: to,
      value: parseInt(value, 16),
      opacity: parseInt(value, 16) > 0 ? 1 : 0.5,
      color: {
        [TransactionType.ERC20]: Colors.COLOR1,
        [TransactionType.ERC721]: Colors.COLOR2,
        [TransactionType.NORMAL]: Colors.COLOR3,
        [TransactionType.INTERNAL]: Colors.COLOR4,
      }[type!],
      extras: [],
    };
    const existingLink = links.find(
      (l) => l.source === from && l.target === to
    );
    existingLink?.extras.push(transaction);
    if (!existingLink) {
      links.push(link);
    } else {
      if (existingLink.color !== link.color) {
        existingLink.color = getColor(existingLink.color, link.color);
      }
    }
  });
  console.timeEnd("Get all tx");
  console.time("Prep payload");
  console.log("last fetch", lastFetchSize);
  const increment = (2 * Math.PI) / lastFetchSize;
  const length = Math.max(lastFetchSize * 8, 300);
  const object = {
    nodes: allAddresses.map((a, i) => {
      return {
        ...a,
        color: "white",
        fontColor: "red",
        y: a.id === a.parent ? y : y + Math.sin(increment * i) * length,
        x: a.id === a.parent ? x : x + Math.cos(increment * i) * length,
      };
    }),
    links,
  };
  console.timeEnd("Prep payload");
  console.log(object);
  return object;
};

export default parseGraph;
