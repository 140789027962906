import "./ContextMenu.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCopy,
  faFileContract,
  faMagnifyingGlassArrowRight,
  faMoneyBillTransfer,
  faPencil,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";

const ContextMenu = ({
  rightClickedNode,
  clickHandlers,
  buttonTexts,
}: {
  rightClickedNode: { x: number; y: number; nodeid: string };
  clickHandlers: any;
  buttonTexts: any;
}) => (
  <div
    style={{
      position: "fixed",
      top: rightClickedNode.y,
      left: rightClickedNode.x,
    }}
    className="contextMenu"
  >
    <div onClick={clickHandlers["copy"]} className="address">
      <FontAwesomeIcon icon={faCopy} className="icon" />
      {rightClickedNode.nodeid.slice(0, 10)}...
    </div>
    <hr />
    <div onClick={clickHandlers["rename"]}>
      <FontAwesomeIcon icon={faPencil} className="icon" />
      Rename
    </div>
    <div onClick={clickHandlers["ftmscan"]}>
      <a
        href={`https://ftmscan.com/address/${rightClickedNode.nodeid}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faMagnifyingGlassArrowRight} className="icon" />
        View FTMscan
      </a>
    </div>
    <div onClick={clickHandlers["tokentracker"]}>
      <a
        href={`https://ftmscan.com/token/${rightClickedNode.nodeid}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFileContract} className="icon" />
        View Token Tracker
      </a>
    </div>
    <hr />
    <div onClick={clickHandlers["clear"]}>
      <FontAwesomeIcon icon={faXmarkCircle} className="icon" />
      Clear node
    </div>
    <div onClick={clickHandlers["disable"]}>
      <FontAwesomeIcon icon={faBan} className="icon" />
      {buttonTexts["disable"] || "Disable node"}
    </div>
    <hr />
    <div onClick={clickHandlers["loadErc20"]}>
      <FontAwesomeIcon icon={faMoneyBillTransfer} className="icon" />
      Fetch ERC20
    </div>
    <div onClick={clickHandlers["loadErc721"]}>
      <FontAwesomeIcon icon={faMoneyBillTransfer} className="icon" />
      Fetch ERC721
    </div>
  </div>
);

export default ContextMenu;
