import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandedNode } from "../types/RenderedGraphNode";

import "./NodeView.scss";

const NodeViewComponent = ({ node }: { node: ExpandedNode }) => (
  <div
    className="myNode"
    key={node.id}
    id={node.id}
    style={{
      backgroundColor: node.color,
    }}
  >
    <div className="row label">
      <FontAwesomeIcon className="icon" icon={faCircleUser} />
      {node.label.split("[")[1].split("]")[0] || "No Label"}
    </div>
    <div className="row full">{node.label.split("]")[1]}</div>
    {/* {n.id === "0xa2d2e63283971e216893348e22bba40999f96738" && console.log(n)} */}
  </div>
);

const NodeView = (n: ExpandedNode) => <NodeViewComponent node={n} />;

export default NodeView;
