import React, { Fragment, useEffect, useState } from "react";
import Loading from "react-loading";
import Launch from "./components/Launch";
import NavBar from "./components/NavBar";
import TransactionGraph from "./components/TransactionGraph";
import ContextMenu from "./components/ContextMenu";
import fetchForAddress, { TransactionType } from "./util/fetchForAddress";
import { Transaction } from "./util/parseGraph";
import RenderedGraphNode from "./types/RenderedGraphNode";

function App() {
  const [labelData, setLabelData] = useState<any>(null);
  const [rightClickedNode, setRightClickedNode] = useState({
    nodeid: "",
    x: 0,
    y: 0,
  });
  const closeContextMenu = () =>
    setRightClickedNode({ nodeid: "", y: 0, x: 0 });
  const [allTransactions, setAllTransactions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [cleared, setCleared] = useState<string[]>([]);
  const [disabled, setDisabled] = useState<string[]>([]);
  const [lastFetchSize, setLastFetchedSize] = useState(0);
  const [selectedNodes, setSelectedNodes] = useState<RenderedGraphNode[]>([]);
  const [loadedTransactions, setLoadedTransactions] = useState<{
    links?: any[];
    nodes?: any[];
  }>({});

  useEffect(() => {
    if (!labelData && localStorage.getItem("labelData") !== null) {
      setLabelData(JSON.parse(localStorage.getItem("labelData")!));
    } else if (!labelData) {
      setLabelData({});
    }
    if (labelData && Object.keys(labelData).length > 0)
      localStorage.setItem("labelData", JSON.stringify(labelData));
  }, [labelData]);

  useEffect(() => {
    if (!cleared.length && localStorage.getItem("cleared") !== null) {
      return setCleared(JSON.parse(localStorage.getItem("cleared")!));
    }
    if (cleared && cleared.length > 0)
      localStorage.setItem("cleared", JSON.stringify(cleared));
  }, [cleared]);

  const loadAddress = async (
    address: string,
    transactionType: TransactionType = TransactionType.NORMAL
  ) => {
    setLoading(true);
    const result = await fetchForAddress(address, transactionType);
    setLastFetchedSize(
      result.reduce((acc: string[], cur: Transaction) => {
        if (!acc.includes(cur.from)) {
          acc = [...acc, cur.from];
        }
        if (!acc.includes(cur.to)) {
          acc = [...acc, cur.to];
        }
        return acc;
      }, []).length
    );
    setAllTransactions([...allTransactions, ...result]);
    setLoading(false);
  };

  if (!loading && allTransactions.length === 0 && !loadedTransactions.nodes) {
    return (
      <Launch
        go={loadAddress}
        load={(data: { transactions: { nodes: any[]; links: any[] } }) => {
          setLastFetchedSize(data.transactions.nodes.length);
          setLoadedTransactions(data.transactions);
        }}
      />
    );
  }
  return (
    <>
      {loading && (
        <div className="loader">
          <h1>Loading</h1>
          <Loading type="cubes" />
        </div>
      )}

      <TransactionGraph
        addressClicked={(address) => loadAddress(address)}
        cleared={cleared}
        disabled={disabled}
        transactions={allTransactions}
        loadedTransactions={loadedTransactions}
        labelData={labelData}
        rightClick={(nodeid: string, event: React.MouseEvent) =>
          setRightClickedNode({ nodeid, x: event.clientX, y: event.clientY })
        }
        selected={selectedNodes}
        setSelectedNodes={setSelectedNodes}
        graphClicked={closeContextMenu}
        lastFetchSize={lastFetchSize}
      />
      {rightClickedNode.nodeid !== "" && (
        <ContextMenu
          rightClickedNode={rightClickedNode}
          clickHandlers={{
            rename: () => {
              const newLabel = prompt("Enter label");
              setLabelData({
                ...labelData,
                [rightClickedNode.nodeid]:
                  newLabel ||
                  labelData[rightClickedNode.nodeid] ||
                  rightClickedNode.nodeid,
              });
              if (newLabel) {
                closeContextMenu();
              }
            },
            clear: () => {
              setCleared([...cleared, rightClickedNode.nodeid]);
              closeContextMenu();
            },
            copy: () => {
              navigator.clipboard
                .writeText(rightClickedNode.nodeid)
                .then(() =>
                  alert(`Copied ${rightClickedNode.nodeid} to clipboard`)
                );
            },
            disable: () => {
              if (disabled.includes(rightClickedNode.nodeid)) {
                setDisabled(
                  disabled.filter((n) => n !== rightClickedNode.nodeid)
                );
                return closeContextMenu();
              }
              setDisabled([...disabled, rightClickedNode.nodeid]);
              closeContextMenu();
            },
            loadErc20: () => {
              loadAddress(rightClickedNode.nodeid, TransactionType.ERC20);
              closeContextMenu();
            },
            loadErc721: () => {
              loadAddress(rightClickedNode.nodeid, TransactionType.ERC721);
              closeContextMenu();
            },
          }}
          buttonTexts={{
            disable: disabled.includes(rightClickedNode.nodeid)
              ? "Enable node"
              : "Disable node",
          }}
        />
      )}
      <NavBar
        cleared={cleared}
        setCleared={setCleared}
        labelData={labelData}
        setLabelData={setLabelData}
        selected={selectedNodes}
      />
    </>
  );
}

export default App;
