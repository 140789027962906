import { useState } from "react";

type Coord = { x: number; y: number; scale?: number };
const Dragable = ({
  onDragRegion,
  translate,
  children,
}: {
  onDragRegion: (region: { point1: Coord; point2: Coord }) => void;
  children: React.ReactNode[];
  translate: Coord;
}) => {
  const [mousePos, setMousePos] = useState<Coord>({ x: 0, y: 0 });
  const [point1, setPoint1] = useState<Coord | null>(null);
  // console.log(mousePos, point1, point2);
  let width = 0;
  let height = 0;
  let top = 0;
  let left = 0;
  if (point1) {
    width = Math.abs(mousePos.x - point1.x);
    height = Math.abs(mousePos.y - point1.y);
    top = Math.min(mousePos.y, point1.y);
    left = Math.min(mousePos.x, point1.x);
  }
  return (
    <div
      tabIndex={0}
      onMouseDownCapture={(e) => {
        if (e.metaKey || e.ctrlKey) {
          setPoint1(mousePos);
          e.stopPropagation();
        }
      }}
      onMouseUpCapture={(e) => {
        if (point1) {
          setPoint1(null);
          e.stopPropagation();
        }
      }}
      onMouseMove={(e) => {
        if (point1 && e.pageX !== mousePos.x && e.pageY !== mousePos.y) {
          onDragRegion({
            point1: {
              x: (point1.x - translate.x) / translate.scale!,
              y: (point1.y - translate.y) / translate.scale!,
            },
            point2: {
              x: (mousePos.x - translate.x) / translate.scale!,
              y: (mousePos.y - translate.y) / translate.scale!,
            },
          });
        }
        setMousePos({ x: e.pageX, y: e.pageY });
      }}
    >
      <div
        className="selection"
        style={{
          top,
          left,
          width,
          height,
          position: "fixed",
          backgroundColor: "rgba(0, 255, 0 , 0.2)",
          borderRadius: 6,
        }}
      ></div>
      {children}
    </div>
  );
};

export default Dragable;
