export const getParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return Array.from(urlParams.entries()).reduce((acc: any, cur) => {
    const [key, val] = cur;
    acc[key] = val;
    return acc;
  }, {});
};

export const setParams = (object: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  Object.entries(object).forEach(([key, val]: [key: any, value: any]) =>
    urlParams.set(key, val)
  );
  window.history.replaceState(null, "", "?" + urlParams.toString());
};
