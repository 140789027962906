import { Transaction } from "./parseGraph";
import { getParams } from "./query";

export enum TransactionType {
  NORMAL = "txlist",
  INTERNAL = "txlistinternal",
  ERC20 = "tokentx",
  ERC721 = "tokennfttx",
}
export enum Networks {
  FTM = "ftmscan",
  ETH = "etherscan",
  MATIC = "polygonscan",
}
const fetchForAddress = async (
  address: string,
  type: TransactionType,
  apiKey: string = getParams().apiKey,
  network: Networks = getParams().network
) => {
  console.log(`Initializing fetch for ${address}`);
  const resp = await fetch(
    `https://api.${network}.com/api?module=account&action=${type}&address=${address}&sort=asc&apikey=${apiKey}`
  );
  const data = await resp.json();
  if (data.status === "0") {
    alert(data.result + data.message);
    return [];
  }
  if (data.result) {
    return data.result.map((transaction: Transaction) => ({
      ...transaction,
      type,
      parent: address,
    }));
  } else {
    alert("That node has too many transactions and failed");
    return [];
  }
};

export default fetchForAddress;
