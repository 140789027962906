import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./NavBar.scss";
import {
  faFileImport,
  faPencil,
  faSave,
  faUpload,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { saveJson } from "../util/fileHandling";
import LabelEditor from "./LabelEditor";
import RenderedGraphNode from "../types/RenderedGraphNode";

const NavBar = ({
  labelData,
  setLabelData,
  cleared,
  setCleared,
  selected,
}: {
  labelData: any;
  setLabelData: Function;
  cleared: any;
  setCleared: Function;
  selected: RenderedGraphNode[];
}) => {
  const [importExport, setImportExport] = useState(false);
  const [editing, setEditing] = useState("");
  if (editing === "labels") {
    return (
      <LabelEditor
        labelData={labelData}
        save={setLabelData}
        cancel={() => setEditing("")}
      />
    );
  }
  return (
    <div className="nav">
      <button
        className="dropdownbutton"
        onClick={() => {
          setImportExport(!importExport);
        }}
      >
        <FontAwesomeIcon className="icon" icon={faFileImport} />
        User data
        <div className="dropdownmenu userdata">
          <div>
            <div>
              <FontAwesomeIcon className="icon" icon={faUpload} /> Load labels
            </div>
            <input
              type="file"
              accept="application/JSON"
              onChange={(e) => {
                const fileReader = new FileReader();
                fileReader.readAsText(e.target.files![0], "UTF-8");
                fileReader.onload = (e) => {
                  setLabelData(JSON.parse(e.target!.result!.toString()));
                };
                setImportExport(false);
              }}
            />
          </div>
          <div
            onClick={() => {
              saveJson(labelData, "FantomLabels.json");
              setImportExport(false);
            }}
          >
            <FontAwesomeIcon className="icon" icon={faSave} />
            Save labels
          </div>
          <div
            onClick={() => {
              setEditing("labels");
            }}
          >
            <FontAwesomeIcon className="icon" icon={faPencil} />
            Edit labels
          </div>
          <hr />
          <div>
            <div>
              <FontAwesomeIcon className="icon" icon={faUpload} /> Load cleared
              nodes
            </div>
            <input
              type="file"
              accept="application/JSON"
              onChange={(e) => {
                const fileReader = new FileReader();
                fileReader.readAsText(e.target.files![0], "UTF-8");
                fileReader.onload = (e) => {
                  setCleared(JSON.parse(e.target!.result!.toString()));
                };
                setImportExport(false);
              }}
            />
          </div>
          <div
            onClick={() => {
              saveJson(cleared, "FantomCleared.json");
              setImportExport(false);
            }}
          >
            <FontAwesomeIcon className="icon" icon={faSave} />
            Save cleared nodes
          </div>
        </div>
      </button>
      {selected.length > 0 && (
        <button
          className="dropdownbutton"
          onClick={() => {
            setImportExport(!importExport);
          }}
        >
          Selection ({selected.length})
          <div className="dropdownmenu selection">
            <div>
              <div>
                <FontAwesomeIcon className="icon" icon={faXmarkCircle} />
                Clear
              </div>
            </div>
          </div>
        </button>
      )}
    </div>
  );
};

export default NavBar;
