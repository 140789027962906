import React, { useEffect, useRef, useState } from "react";
import { Graph } from "react-d3-graph";
import RenderedGraphNode from "../types/RenderedGraphNode";
import parseGraph from "../util/parseGraph";
import { graphToJson } from "../util/parseGraphSaveState";
import Dragable from "./Dragable";
import NodeView from "./NodeView";
import "./TransactionGraph.scss";

const filterDuplicateNodes = (node: any, index: number, original: any[]) =>
  index === original.findIndex((n) => n.id === node.id);

const filterDuplicateLinks = (link: any, index: number, original: any[]) =>
  index ===
  original.findIndex(
    (l) => l.source === link.source && l.target === link.target
  );

const getOnlyNewPositions = (newNodes: any[], oldNodes: any[]) => {
  for (const node of newNodes) {
    const oldNode = oldNodes.find((n: { id: string }) => n.id === node.id);
    if (oldNode) {
      node.x = oldNode.x;
      node.y = oldNode.y;
    }
  }
  return newNodes;
};

const TransactionGraph = ({
  transactions,
  addressClicked,
  labelData,
  rightClick,
  graphClicked,
  cleared,
  disabled,
  lastFetchSize,
  selected,
  setSelectedNodes,
  loadedTransactions,
}: {
  transactions: Array<any>;
  labelData: any;
  rightClick: Function;
  graphClicked: (event: React.MouseEvent<Element, MouseEvent>) => any;
  addressClicked: (address: string) => any;
  cleared: string[];
  disabled: string[];
  selected: RenderedGraphNode[];
  setSelectedNodes: (nodes: RenderedGraphNode[]) => void;
  lastFetchSize: number;
  loadedTransactions: { links?: any[]; nodes?: any[] };
}) => {
  const [parsedTransactions, setParsedTransactions] = useState<{
    links?: any[];
    nodes?: any[];
  }>({ links: [], nodes: [] });

  const [dragRegion, setDragRegion] = useState({
    point1: { x: 0, y: 0 },
    point2: { x: 0, y: 0 },
  });
  const [paused, setPaused] = useState(false);
  const [gravity, setGravity] = useState(false);
  const [depth, setDepth] = useState({ x: 0, y: 0 });
  const graphInstance = useRef(null);

  useEffect(() => {
    if (loadedTransactions.nodes && !transactions.length) {
      setParsedTransactions(loadedTransactions);
    } else if (!loadedTransactions.nodes && transactions.length) {
      const newTransactions = parseGraph(
        transactions,
        depth.x,
        depth.y,
        lastFetchSize
      );
      setParsedTransactions({
        links: newTransactions.links,
        nodes: getOnlyNewPositions(
          newTransactions.nodes,
          parsedTransactions.nodes!
        ),
      });
    } else if (loadedTransactions.nodes && transactions.length) {
      const newTransactions = parseGraph(
        transactions,
        depth.x,
        depth.y,
        lastFetchSize
      );
      setParsedTransactions({
        nodes: [
          ...loadedTransactions.nodes!,
          ...getOnlyNewPositions(
            newTransactions.nodes,
            parsedTransactions.nodes!
          ),
        ].filter(filterDuplicateNodes),
        links: [...loadedTransactions.links!, ...newTransactions.links].filter(
          filterDuplicateLinks
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions, loadedTransactions, depth, lastFetchSize]);

  const [path, setPath] = useState([] as string[]);
  if (!parsedTransactions.links || !parsedTransactions.nodes) {
    return null;
  }
  const myConfig = {
    nodeHighlightBehavior: true,
    staticGraphWithDragAndDrop: !gravity,
    automaticRearrangeAfterDropNode: false,
    width: window.innerWidth,
    height: window.innerHeight,
    minZoom: 0.05,
    node: {
      fontSize: 12,
      highlightStrokeColor: "blue",
      labelProperty: (n: any) => {
        return n.label + n.id;
      },
    },
    link: {
      // labelProperty: (link: any) => (link.value / 1000).toLocaleString(),
      renderLabel: false,
      fontColor: "white",
      color: "rgb(255,255,255)",
      strokeWidth: 5,
      highlightColor: "white",
    },
  };

  const getIsSelected = (n: RenderedGraphNode) =>
    n.x >= Math.min(dragRegion.point1.x, dragRegion.point2.x) - 20 &&
    n.x <= Math.max(dragRegion.point1.x, dragRegion.point2.x) + 20 &&
    n.y >= Math.min(dragRegion.point1.y, dragRegion.point2.y) - 20 &&
    n.y <= Math.max(dragRegion.point1.y, dragRegion.point2.y) + 20;

  const graph = document.getElementById("graph-id-graph-container-zoomable");
  const translate = { x: 0, y: 0, scale: 1 };
  if (graph && graph.getAttribute("transform")) {
    const transform = graph.getAttribute("transform")!;
    translate.x = parseInt(transform.split("translate(")[1].split(",")[0]);
    translate.y = parseInt(transform.split(",")[1].split(")")[0]);
    translate.scale = parseFloat(transform.split("scale(")[1].split(")")[0]);
  }
  const getColor = (n: {
    x: number;
    y: number;
    id: string;
    color: string;
    selected: boolean;
  }) => {
    if (n.selected) {
      return "rgba(99, 52, 130, 0.677)";
    }
    if (disabled.includes(n.id)) {
      return "rgb(120,120,120)";
    }
    return "rgba(99, 52, 130, 0.677)";
  };
  return (
    <Dragable
      onDragRegion={(newDragRegion) => {
        const isNew =
          Math.abs(dragRegion.point1.x - newDragRegion.point1.x) > 20 ||
          Math.abs(dragRegion.point1.y - newDragRegion.point1.y) > 20;
        if (isNew) {
          setSelectedNodes([]);
        }
        const nodes = parsedTransactions.nodes!.map((n) => ({
          ...n,
          selected: getIsSelected(n),
        }));
        setParsedTransactions({
          nodes,
          links: parsedTransactions.links,
        });
        setSelectedNodes(nodes.filter((n) => n.selected));
        setDragRegion(newDragRegion);
      }}
      translate={translate}
    >
      <Graph
        onClickGraph={graphClicked}
        id="graph-id" // id is mandatory
        ref={graphInstance}
        data={{
          nodes: parsedTransactions
            .nodes!.filter((node) => !cleared.includes(node.id))
            .map((n) => ({
              ...n,
              label: `[${labelData[n.id] || ""}]`,
              color: getColor(n),
              size: { width: 2000, height: 1000 },
              viewGenerator: NodeView,
              renderLabel: false,
            })),
          links: parsedTransactions.links!.filter(
            (link: { source: string; target: string }) =>
              !cleared.includes(link.target) && !cleared.includes(link.source)
          ),
        }}
        config={{ ...myConfig }}
        // @ts-ignore
        onClickNode={(
          _nodeid: any,
          node: { id: any; y: number; x: number }
        ) => {
          setDepth({ x: node.x, y: node.y });
          const address: string = node.id;
          if (disabled.includes(address)) {
            return alert("Disabled");
          }
          addressClicked(address);
          setPath([...path, address]);
        }}
        onRightClickNode={(event, nodeid) => {
          event.preventDefault();
          rightClick(nodeid, event);
        }}
        onClickLink={console.log}
        onNodePositionChange={(nodeid, x, y) => {
          const nodeWithOldPos = parsedTransactions.nodes!.find(
            (n) => n.id === nodeid
          );
          const deltaX = x - nodeWithOldPos.x;
          const deltaY = y - nodeWithOldPos.y;
          let nodesToChange = [nodeid];
          if (selected.find((s) => s.id === nodeid)) {
            nodesToChange = selected.map((s) => s.id);
          }
          setParsedTransactions({
            links: parsedTransactions.links,
            nodes: parsedTransactions.nodes!.map((n) =>
              nodesToChange.includes(n.id)
                ? { ...n, x: n.x + deltaX, y: n.y + deltaY }
                : n
            ),
          });
          //@ts-expect-error Weirdness
          graphInstance.current.resetNodesPositions();
        }}
      />
      <div className="status">
        <span>
          {`${parsedTransactions.nodes!.length} nodes / ${
            transactions.length
          } transactions`}
        </span>
        <button onClick={() => setPaused(!paused)}>
          {paused ? "Network" : "Network"}
        </button>
        <button onClick={() => setGravity(!gravity)}>
          {gravity ? "Grav " : "Grav"}
        </button>
        <button onClick={() => graphToJson(parsedTransactions)}>
          Save State
        </button>
      </div>
    </Dragable>
  );
};

export default TransactionGraph;
