import {
  faCheck,
  faCircleMinus,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import "./LabelEditor.scss";
const LabelEditor = ({
  labelData,
  cancel,
  save,
}: {
  labelData: any;
  cancel: Function;
  save: Function;
}) => {
  const [newLabels, setNewLabels] = useState<any>({});
  return (
    <div className="container">
      <div className="editor labelEditor">
        <div className="closeBar">
          <span>Label Editor</span>
          <FontAwesomeIcon
            onClick={() => cancel()}
            className="icon"
            icon={faCircleXmark}
          />
        </div>
        <hr />
        {Object.keys(labelData).map((key) => (
          <div key={key}>
            <span>{key}</span>
            <input
              type="text"
              placeholder={labelData[key]}
              onChange={(e) => {
                setNewLabels({ ...newLabels, [key]: e.target.value });
              }}
            />
            {newLabels[key] && newLabels[key] !== labelData[key] && (
              <FontAwesomeIcon
                onClick={() => save({ ...labelData, ...newLabels })}
                className="icon edit"
                icon={faCheck}
              />
            )}
            <FontAwesomeIcon
              onClick={() =>
                save(
                  Object.entries(labelData).reduce((acc: any, [k, v]) => {
                    if (key !== k) {
                      acc[k] = v;
                    }
                    return acc;
                  }, {})
                )
              }
              className="icon clear"
              icon={faCircleMinus}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LabelEditor;
