import { saveJson } from "./fileHandling";
import { getParams, setParams } from "./query";

export const graphToJson = (transactions: any) => {
  return saveJson({ transactions, params: getParams() }, "GraphState.json");
};

export const jsonToGraph = (json: string) => {
  const data = JSON.parse(json);
  setParams(data.params);
  return data;
};
