import { faMagnifyingGlassArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Networks } from "../util/fetchForAddress";
import { jsonToGraph } from "../util/parseGraphSaveState";
import { getParams, setParams } from "../util/query";
import "./Launch.scss";

const Launch = ({
  go,
  load,
}: {
  go: (address: string) => void;
  load: Function;
}) => {
  const query = getParams();
  const [address, setAddress] = useState(
    query.address || "0x7d828b25640079d2461fde495f4b129c86abce12"
  );
  const [apiKey, setApiKey] = useState(
    query.apiKey || "E737PZMXYMIZV4BXXYF354GFGFY6TU3SXU"
  );
  const [network, setNetwork] = useState(query.network || "ftmscan");
  return (
    <div className="launch">
      <svg
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="rgb(159, 29, 29)"
          fillOpacity="1"
          d="M0,64L34.3,90.7C68.6,117,137,171,206,213.3C274.3,256,343,288,411,266.7C480,245,549,171,617,154.7C685.7,139,754,181,823,192C891.4,203,960,181,1029,186.7C1097.1,192,1166,224,1234,202.7C1302.9,181,1371,107,1406,69.3L1440,32L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        ></path>
      </svg>
      <div className="fill"></div>
      <div className="container">
        <div className="modal">
          <h2>Fantom Graph Visualizer</h2>
          <h4>
            <span>Address:</span>
            <input
              type="text"
              placeholder="Address"
              value={address}
              onClick={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.select();
              }}
              onChange={(e) => setAddress(e.target.value)}
            />
          </h4>
          <h4>
            <span>API:</span>
            <input
              type="text"
              placeholder="API Key"
              value={apiKey}
              onClick={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.select();
              }}
              onChange={(e) => setApiKey(e.target.value)}
            />
          </h4>
          <h4 className="network">
            <span>Network</span>
            <select
              onChange={(e) => {
                const newKey = prompt("Enter new API token");
                if (!newKey) {
                  return;
                }
                setApiKey(newKey);
                setNetwork(e.target.value);
              }}
              value={network}
            >
              {Object.keys(Networks).map((key) => (
                <option
                  key={key}
                  value={Networks[key as keyof typeof Networks]}
                >
                  {key}
                </option>
              ))}
            </select>
          </h4>
          <h4 className="load">
            <button
              onClick={() => {
                setParams({ address, apiKey, network });
                go(address);
              }}
            >
              Query
              <FontAwesomeIcon
                className="icon"
                icon={faMagnifyingGlassArrowRight}
              />
            </button>
            <div className="import">
              <span>Import State</span>
              <input
                type="file"
                accept="application/JSON"
                onChange={(e) => {
                  const fileReader = new FileReader();
                  fileReader.readAsText(e.target.files![0], "UTF-8");
                  fileReader.onload = (e) => {
                    const data = jsonToGraph(e.target!.result!.toString());
                    load(data);
                  };
                }}
              />
            </div>
          </h4>

          {process.env.REACT_APP_COMMIT_REF && (
            <div className="commit">
              version: {process.env.REACT_APP_COMMIT_REF}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Launch;
